import gql from 'graphql-tag'

export const CloneDeliveryByObjectIdGQL = gql`
  mutation ($objectId: String!, $cloneType: String!) {
    cloneDeliveryByObjectId(objectId: $objectId, cloneType: $cloneType) {
      pickupTime
      vehicleType
      deliveryType
      userObjectId
      window
      stops {
        id
        type
        address1
        address2
        location {
          lat
          lng
        }
        items {
          notes
          size
          category
          customId
        }
        postcode
        name
        phone
        pinCode
        qrCode
        additionalDriverEquipment
      }
    }
  }
`
