export class AppError extends Error {
  public name: string
  public description: string
  public message: string
  public data?: Record<string, unknown>

  /**
   * Main error class used by the app.
   *
   * @param message      Message
   * @param description  Description of the error
   * @param data         Additional data if needed representing the error if needed
   */
  constructor(
    message?: string,
    description?: string,
    data?: Record<string, unknown>,
  ) {
    super(message)
    this.name = 'AppError'
    this.message = message || 'InternalError'
    this.description = description || ''
    this.data = data

    // @see https://stackoverflow.com/questions/41102060/typescript-extending-error-class
    Object.setPrototypeOf(this, AppError.prototype)
  }
}
