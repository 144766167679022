













import { Vue, Component, Prop } from 'nuxt-property-decorator'
import SvgLogo from '~/assets/svg/logo.svg'

@Component({
  components: {
    SvgLogo,
  },
})
export default class Logo extends Vue {
  @Prop({ default: 24 }) height!: number
  @Prop({ default: 'auto' }) width!: number | string
  @Prop({ default: false }) background!: boolean
  @Prop({ default: false }) isWhite!: boolean
}
