import gql from 'graphql-tag'

export const GetProvidersGQL = gql`
  {
    getProviders {
      id
      name
      image
      imageHeight
      providerInfo {
        email
        phone
        text1
        supportLink
      }
    }
  }
`
