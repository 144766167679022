import gql from 'graphql-tag'

export const GetUserServiceLevelsGQL = gql`
  {
    getUserServiceLevels {
      id
      name
    }
  }
`
