import gql from 'graphql-tag'

export const GetPoolDeliveriesForDeliveryObjectIdGQL = gql`
  query ($id: String!) {
    getPoolDeliveriesForDeliveryObjectId(objectId: $id) {
      id
      order {
        user {
          company
          email
        }
      }
      status
      code
      objectId
      completedAt
    }
  }
`
