import BPromise from 'bluebird'

import { Plugin } from '@nuxt/types'

import { FirebaseService } from '../services'

const plugin: Plugin = () => {
  return new BPromise(resolve => {
    // wait for auth from firebase before starting the app
    FirebaseService.auth.onAuthStateChanged(() => {
      return resolve()
    })
  })
}

export default plugin
