import gql from 'graphql-tag'

export const GetDeliveryCommentsByObjectIdGQL = gql`
  query ($objectId: String!, $filter: DeliveryCommentFilter) {
    getCommentByDeliveryObjectId(objectId: $objectId, filter: $filter) {
      total
      comments {
        id
        comment
        commentedBy {
          id
          uid
          email
          firstName
        }
        createdAt
      }
    }
  }
`
