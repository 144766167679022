import gql from 'graphql-tag'

export const GetUserBillingTypesGQL = gql`
  {
    getUserBillingTypes {
      id
      name
    }
  }
`
