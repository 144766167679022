import gql from 'graphql-tag'

export const PlaceDeliveryOrderGQL = gql`
  mutation (
    $userObjectId: String!
    $delivery: PlaceDeliveryOrderDeliveryRequest
  ) {
    placeDeliveryOrder(userObjectId: $userObjectId, delivery: $delivery) {
      deliveries {
        code
        objectId
      }
    }
  }
`
