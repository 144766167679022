import gql from 'graphql-tag'

export const updateProviderDetailsGQL = gql`
  mutation (
    $objectId: String!
    $provider: String!
    $deliveryId: String!
    $cost: String!
    $confirmation: Boolean!
  ) {
    updateProviderDetails(
      objectId: $objectId
      provider: $provider
      deliveryId: $deliveryId
      cost: $cost
      confirmation: $confirmation
    )
  }
`
