import gql from 'graphql-tag'

export const GetDeliveryQuotationGQL = gql`
  mutation ($userObjectId: String!, $delivery: DeliveryRequest) {
    getDeliveryQuotation(userObjectId: $userObjectId, delivery: $delivery) {
      ridaQuotation {
        amount
        perStops
        lineItems {
          name
          amount
        }
      }
      providerQuotation {
        providers {
          name
          amount
        }
      }
    }
  }
`
