import gql from 'graphql-tag'

export const GetIncompleteDeliveriesGQL = gql`
  query ($filter: IncompleteDeliveryFilter) {
    getIncompleteDeliveries(filter: $filter) {
      total
      deliveries {
        order {
          pickupTime
          user {
            company
            email
            serviceLevel
          }
        }
        id
        objectId
        code
        status
        waypoints {
          type
          address1
          address2
          name
          phone
        }
        driverInfo {
          name
        }
      }
    }
  }
`
