import gql from 'graphql-tag'

export const GetRacingProvidersByDeliveryObjectIdGQL = gql`
  query ($id: String!) {
    getRacingProvidersByDeliveryObjectId(deliveryObjectId: $id) {
      id
      deliveryId
      deliveryDriver {
        name
      }
      deliveryProvider
      providerDeliveries {
        providerDeliveryId
        waypoint {
          address1
          address2
        }
        order {
          code
        }
      }
      status
      createdTime
      tips {
        id
        amount
        createdAt
        createdBy {
          email
        }
      }
    }
  }
`
