import { GetPoolDeliveriesForDeliveryObjectIdGQL } from '../graphql/pool/GetPoolDeliveriesForDeliveryObjectId'
import { GetPoolOrdersForOrderObjectIdGQL } from '../graphql/pool/GetPoolOrdersForOrderObjectId'
import { ApolloService } from './Apollo'
import {
  V1RidaApiGetPoolDeliveriesForDeliveryObjectIdResponse,
  V1RidaApiGetPoolOrdersForOrderObjectIdResponse,
} from './Type'

class Pool {
  public static async getPoolOrdersForOrderObjectId(id: string) {
    const response =
      await ApolloService.client.query<V1RidaApiGetPoolOrdersForOrderObjectIdResponse>(
        {
          query: GetPoolOrdersForOrderObjectIdGQL,
          variables: {
            id,
          },
        },
      )
    return response.data
  }

  public static async getPoolDeliveriesForDeliveryObjectId(id: string) {
    const response =
      await ApolloService.client.query<V1RidaApiGetPoolDeliveriesForDeliveryObjectIdResponse>(
        {
          query: GetPoolDeliveriesForDeliveryObjectIdGQL,
          variables: {
            id,
          },
        },
      )
    return response.data
  }
}

export { Pool as PoolService }
