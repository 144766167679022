import gql from 'graphql-tag'

export const GetDeliveryHistoryByObjectIdGQL = gql`
  query ($objectId: String!) {
    getDeliveryHistoryByObjectId(objectId: $objectId) {
      delivery {
        code
        status
        objectId
      }
      histories {
        fromDeliveryStatus
        toDeliveryStatus
        comment
        createdAt
        fromMergedDeliveries {
          code
          objectId
        }
        toMergedDeliveries {
          code
          objectId
        }
        createdBy {
          email
        }
      }
    }
  }
`
