import gql from 'graphql-tag'

export const GetUserInfoGQL = gql`
  {
    getUserInfo {
      uid
      email
      firstName
      lastName
      phone
      company
      role
      savedContacts {
        id
        name
        phone
      }
      savedPlaces {
        id
        address1
        address2
        name
        lat
        lng
      }
    }
  }
`
