import { actionTree, getAccessorType, mutationTree } from 'typed-vuex'
import { GetterTree } from 'vuex'

import { FirebaseService, UserService } from '../services'

export const state = () => ({
  user: null as { id: number; name: string; role: string } | null,
  auth: { isLoggedIn: false },
  app: { splashScreen: true },
})

export type RootState = ReturnType<typeof state>

export const getters: GetterTree<RootState, RootState> = {
  user: state => state.user,
}

export const mutations = mutationTree(state, {
  SET_USER_STATE: (
    state,
    user: { id: number; name: string; role: string } | null,
  ) => {
    state.user = user
  },
  SET_AUTH_STATE: (state, auth: { isLoggedIn: boolean }) => {
    state.auth = auth
  },
  SET_SPLASHSCREEN_STATE: (state, show: boolean) => {
    state.app.splashScreen = show
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    async isAuthenticated({ commit }) {
      try {
        const { getUserInfo } = await UserService.getInfo()
        commit('SET_USER_STATE', {
          id: getUserInfo.id,
          name: getUserInfo.firstName || '',
          role: getUserInfo.role,
        })
        commit('SET_AUTH_STATE', { isLoggedIn: true })
        return true
      } catch (error) {
        commit('SET_USER_STATE', null)
        commit('SET_AUTH_STATE', { isLoggedIn: false })
        return false
      }
    },

    async logout({ commit }) {
      await FirebaseService.auth.signOut()
      commit('SET_USER_STATE', null)
      commit('SET_AUTH_STATE', { isLoggedIn: false })
    },

    showSplashScreen({ commit }) {
      commit('SET_SPLASHSCREEN_STATE', true)
    },

    hideSplashScreen({ commit }) {
      commit('SET_SPLASHSCREEN_STATE', false)
    },
  },
)

export const accessorType = getAccessorType({
  state,
  getters,
  mutations,
  actions,
})
