import gql from 'graphql-tag'

export const GetRoutesGQL = gql`
  query ($filter: GetRouteFilter!) {
    getRoutes(filter: $filter) {
      routes {
        id
        status
        pickupTime
        assignedTime
        user {
          company
          email
        }
        racings {
          totalTipAmount
          deliveryProvider
          deliveryId
          status
        }
        stops {
          type
          address1
          address2
          name
          phone
          status
          items {
            customId
          }
          delivery {
            objectId
            code
            deliveryWindow {
              start
              end
            }
            order {
              delivery {
                type
              }
            }
          }
        }
      }
      totalRoutes
      merchants {
        id
        uid
        email
        phone
        company
      }
    }
  }
`
