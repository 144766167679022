






import Icon from '~/assets/icons/visibility-off.svg'

import { Vue, Component } from 'nuxt-property-decorator'

@Component({
  components: {
    Icon,
  },
})
export default class VisibilityOff extends Vue {}
