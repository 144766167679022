import gql from 'graphql-tag'

export const AddMultipleCreditsGQL = gql`
  mutation (
    $creditType: String!
    $description: String
    $users: [MultipleUserCredit!]
  ) {
    addMultipleCredits(
      creditType: $creditType
      description: $description
      users: $users
    )
  }
`
