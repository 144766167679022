// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/fonts/CircularStd-Book.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/fonts/CircularStd-Bold.otf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:CircularStd;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\");font-style:normal;font-weight:400}@font-face{font-family:CircularStd;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"opentype\");font-style:normal;font-weight:700}.loading-page[data-v-063ed80a]{position:fixed;top:0;left:0;width:100vw;height:100vh;z-index:1001;background-color:#fff;display:flex;align-items:center;align-content:center;justify-content:center;flex-flow:column wrap;font-family:\"CircularStd\",sans-serif;-webkit-font-smoothing:antialiased;padding-bottom:50px}@media (max-width:600px){.loading-page[data-v-063ed80a]{justify-content:flex-start;padding-bottom:0}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
