import gql from 'graphql-tag'

export const GetRegisteredAddressesGQL = gql`
  {
    getRegisteredAddresses {
      id
      postcode
      objectId
      vendorId
      address
      name
      phone
      updatedTime
      createdTime
    }
  }
`
