import { GetRegisteredAddressesGQL } from '../graphql/address/GetRegisteredAddresses'
import { CreateRegisteredAddressGQL } from '../graphql/address/CreateRegisteredAddress'
import { ApolloService } from './Apollo'
import { V1RidaApiGetRegisteredAddressesResponse } from './Type'
import { DeleteRegisteredAddressGQL } from '../graphql/address/DeleteRegisteredAddress'
import { UpdateRegisteredAddressGQL } from '../graphql/address/UpdateRegisteredAddress'

class Address {
  public static async getRegisteredAddresses() {
    const response =
      await ApolloService.client.query<V1RidaApiGetRegisteredAddressesResponse>(
        {
          query: GetRegisteredAddressesGQL,
        },
      )
    return response.data
  }

  // function to create Registered address
  public static async createRegisteredAddress(
    address: string,
    postcode: string,
    name: string,
    phone: string,
  ) {
    await ApolloService.client.mutate({
      mutation: CreateRegisteredAddressGQL,
      variables: {
        address,
        postcode,
        name,
        phone,
      },
    })
  }

  // function to delete registered address
  public static async deleteRegisteredAddress(objectId: string) {
    await ApolloService.client.mutate({
      mutation: DeleteRegisteredAddressGQL,
      variables: {
        objectId,
      },
    })
  }

  // function to update registered address
  public static async updateRegisteredAddress(
    objectId: string,
    address: string,
    postcode: string,
    name: string,
    phone: string,
  ) {
    await ApolloService.client.mutate({
      mutation: UpdateRegisteredAddressGQL,
      variables: {
        objectId,
        address,
        postcode,
        name,
        phone,
      },
    })
  }
}

export { Address as AddressService }
