
















import SideMenu from '~/components/layout/SideMenu.vue'
import TopNav from '~/components/layout/TopNav.vue'
import UtilityMixins from '~/mixins/Utility'

import { Component, mixins, Watch } from 'nuxt-property-decorator'

@Component({
  components: {
    SideMenu,
    TopNav,
  },
})
export default class MainLayout extends mixins(UtilityMixins) {
  public get height() {
    return window.innerHeight
  }

  public get splashScreen() {
    return this.$accessor.app.splashScreen
  }

  @Watch('splashScreen')
  public onSplashScreenChanged() {
    if (this.splashScreen) {
      this.$nextTick(() => {
        this.$nuxt.$loading.start()
      })
    } else {
      this.$nextTick(() => {
        this.$nuxt.$loading.finish()
      })
    }
  }

  public mounted() {
    this.$accessor.hideSplashScreen()
  }
}
