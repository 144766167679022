



















import { Component, mixins } from 'nuxt-property-decorator'
import UtilityMixins from '~/mixins/Utility'
import Logo from '~/components/Logo.vue'

@Component({
  components: {
    Logo,
  },
})
export default class Loader extends mixins(UtilityMixins) {
  public loading = false
  public get innerHeight() {
    return window.innerHeight
  }

  public start() {
    if (this.$accessor.app.splashScreen) {
      this.loading = true
    }
  }

  public finish() {
    this.loading = false
  }
}
