import gql from 'graphql-tag'

export const AddTipForDeliveryByObjectIdGQL = gql`
  mutation ($objectId: String!, $tipAmount: String!, $providers: [String]) {
    addTipForDeliveryByObjectId(
      objectId: $objectId
      tipAmount: $tipAmount
      providers: $providers
    )
  }
`
