import gql from 'graphql-tag'

export const GetPoolOrdersForOrderObjectIdGQL = gql`
  query ($id: String!) {
    getPoolOrdersForOrderObjectId(objectId: $id) {
      id
      userId
      orderId
      batchId
      user {
        company
        email
      }
      order {
        id
        code
        status
        objectId
      }
    }
  }
`
