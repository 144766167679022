import 'firebase/auth'

import { FirebaseApp, getApps, initializeApp } from 'firebase/app'

import { Auth, getAuth } from '@firebase/auth'

import { UtilityService } from './Utility'

class Firebase {
  public auth!: Auth
  public apps!: FirebaseApp[]

  public init() {
    const envConfig = UtilityService.getConfig()

    const firebaseApp = initializeApp({
      apiKey: envConfig.FIREBASE.API_KEY,
      authDomain: envConfig.FIREBASE.AUTH_DOMAIN,
      databaseURL: envConfig.FIREBASE.DATABASE_URL,
      projectId: envConfig.FIREBASE.PROJECT_ID,
      storageBucket: envConfig.FIREBASE.STORAGE_BUCKET,
      messagingSenderId: envConfig.FIREBASE.MESSAGE_SENDER_ID,
      appId: envConfig.FIREBASE.APP_ID,
    })

    this.auth = getAuth(firebaseApp)
    this.apps = getApps()
  }

  public async getAccessToken() {
    if (this.auth.currentUser) {
      try {
        const token = await this.auth.currentUser.getIdToken()
        return token
      } catch (error) {
        return false
      }
    } else {
      return false
    }
  }
}

const fb = new Firebase()

export { fb as FirebaseService }
