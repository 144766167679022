import { Dayjs } from 'dayjs'

import { AddCreditGQL } from '../graphql/credit/AddCredit'
import { AddMultipleCreditsGQL } from '../graphql/credit/AddMultipleCredits'
import { GetCreditTransactionsByUserIdGQL } from '../graphql/credit/GetCreditTransactionsByUserId'
import { GetCreditTypesGQL } from '../graphql/credit/GetCreditTypes'
import { UpdateCreditTransactionGQL } from '../graphql/credit/UpdateCreditTransaction'
import { ApolloService } from './Apollo'
import {
  V1RidaApiGetCreditTransactionsByUserIdResponse,
  V1RidaApiGetCreditTypeResponse,
} from './Type'

class Credit {
  public static async getCreditTransactionsByUserId(
    uid: string,
    filterOptions?: {
      itemsPerPage?: number
      pageNumber?: number
    },
  ) {
    const response =
      await ApolloService.client.query<V1RidaApiGetCreditTransactionsByUserIdResponse>(
        {
          query: GetCreditTransactionsByUserIdGQL,
          variables: {
            uid,
            filter: {
              itemsPerPage: filterOptions?.itemsPerPage,
              pageNumber: filterOptions?.pageNumber,
            },
          },
        },
      )
    return response.data
  }

  public static async getCreditTypes() {
    const response =
      await ApolloService.client.query<V1RidaApiGetCreditTypeResponse>({
        query: GetCreditTypesGQL,
      })
    return response.data
  }

  public static async addCredit(
    amount: string,
    creditType: string,
    description: string | null,
    userUid: string,
    totalOrderValue: string | null,
    orderId: string | null,
  ) {
    await ApolloService.client.mutate({
      mutation: AddCreditGQL,
      variables: {
        amount,
        creditType,
        description,
        userUid,
        totalOrderValue,
        orderId,
      },
    })
  }

  public static async addMultipleCredits(
    users: { amount: string; email: string }[],
    creditType: string,
    description: string | null,
  ) {
    await ApolloService.client.mutate({
      mutation: AddMultipleCreditsGQL,
      variables: {
        users,
        creditType,
        description,
      },
    })
  }

  public static async updateCreditTransaction(
    transactionId: number,
    updatedData: {
      createdTime: Dayjs
    },
  ) {
    await ApolloService.client.mutate({
      mutation: UpdateCreditTransactionGQL,
      variables: {
        creditTransactionId: transactionId,
        createdTime: updatedData.createdTime.toISOString(),
      },
    })
  }
}

export { Credit as CreditService }
