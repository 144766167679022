








































import SvgLogo from '~/assets/svg/logo.svg'
import SvgOrders from '~/assets/svg/header/orders.svg'
import SvgMenuBurgerMenu from '~/assets/svg/header/burger_menu.svg'
import SvgBack from '~/assets/svg/back.svg'
import { Vue, Component, Prop, Watch } from 'nuxt-property-decorator'
import _ from 'lodash'
import { NUXT_EVENT } from '~/services'

@Component({
  components: {
    SvgLogo,
    SvgOrders,
    SvgMenuBurgerMenu,
    SvgBack,
  },
})
export default class TopNav extends Vue {
  @Prop({ default: true, type: Boolean }) block!: boolean
  @Prop({ default: false, type: Boolean }) dark!: boolean
  @Prop({ default: 0 }) offsetY!: number
  public offsetTop = 0
  public height = 60

  @Watch('offsetYPosition')
  public onOffsetYPositionChange(val: number) {
    this.$nuxt.$emit('scroll-y-offset', val)
  }

  public get offsetYPosition() {
    return this.offsetTop >= this.offsetY
  }

  public get hasBackIcon() {
    return true
  }

  public get wrapperStyles() {
    return {
      flex: this.block ? `0 0 ${this.height}px` : '',
      height: this.block ? this.height + 'px' : '',
    }
  }

  public get headerTitle() {
    if (_.includes(this.$route.path, 'orders')) {
      return 'Orders'
    } else if (_.includes(this.$route.path, 'merchants')) {
      return 'Merchants'
    } else {
      return ''
    }
  }

  public mounted() {
    window.addEventListener('scroll', this.getOffsetTop)
  }

  public beforeDestroy() {
    window.removeEventListener('scroll', this.getOffsetTop)
  }

  public getOffsetTop() {
    this.offsetTop = Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop,
    )
  }

  public backTo() {
    this.$router.go(-1)
  }

  public toggleBurgerBar() {
    this.$nuxt.$emit(NUXT_EVENT.DRAWER_OPEN)
  }
}
