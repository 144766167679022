import gql from 'graphql-tag'

export const UpdateUserDetailsGQL = gql`
  mutation (
    $objectId: String
    $company: String
    $serviceLevel: String
    $userRole: String
    $billingType: String
  ) {
    updateUserDetails(
      objectId: $objectId
      company: $company
      serviceLevel: $serviceLevel
      userRole: $userRole
      billingType: $billingType
    )
  }
`
