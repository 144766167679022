import { GetSidebarNotificationGQL } from '../graphql/notification/GetSidebarNotification'
import { ApolloService } from './Apollo'
import { V1RidaApiGetSidebarNotificationResponse } from './Type'

class Notification {
  public static async getSidebarNotification() {
    const response =
      await ApolloService.client.query<V1RidaApiGetSidebarNotificationResponse>(
        {
          query: GetSidebarNotificationGQL,
        },
      )
    return response.data
  }
}

export { Notification as NotificationService }
