import gql from 'graphql-tag'

export const AddCreditGQL = gql`
  mutation (
    $amount: String!
    $userUid: String!
    $creditType: String!
    $description: String
    $totalOrderValue: String
    $orderId: String
  ) {
    addCredit(
      amount: $amount
      userUid: $userUid
      creditType: $creditType
      description: $description
      totalOrderValue: $totalOrderValue
      orderId: $orderId
    )
  }
`
