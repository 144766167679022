import NProgress from 'nprogress'

import { Plugin } from '@nuxt/types'
import { ApolloHelpers } from '@nuxtjs/apollo'

import { FirebaseService } from '../services'
import { ApolloService } from '../services/Apollo'
import { accessorType } from '../store'

NProgress.configure({ showSpinner: true, trickleSpeed: 100 })

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $apolloHelpers: ApolloHelpers
    $accessor: typeof accessorType
  }
}

const plugin: Plugin = context => {
  const app = context.app
  FirebaseService.init()
  ApolloService.init(app.apolloProvider.defaultClient)
  app.router!.beforeEach((_to, _from, next) => {
    NProgress.start()
    next()
  })

  app.router!.afterEach(() => {
    NProgress.done()
  })
}

export default plugin
