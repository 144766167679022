import {
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from 'firebase/auth'

import { FirebaseService } from './Firebase'

class Auth {
  public static async login(email: string, password: string) {
    return await signInWithEmailAndPassword(
      FirebaseService.auth,
      email,
      password,
    )
  }

  public static async forgotPassword(email: string) {
    return await sendPasswordResetEmail(FirebaseService.auth, email)
  }
}

export { Auth as AuthService }
