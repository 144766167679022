import gql from 'graphql-tag'

export const GetUserRolesGQL = gql`
  {
    getUserRoles {
      id
      name
    }
  }
`
