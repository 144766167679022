












import { Component, mixins, Watch } from 'nuxt-property-decorator'
import UtilityMixins from '~/mixins/Utility'

@Component
export default class AuthLayout extends mixins(UtilityMixins) {
  public get paddingTop() {
    return window.innerHeight
  }

  public get splashScreen() {
    return this.$accessor.app.splashScreen
  }

  @Watch('splashScreen')
  public onSplashScreenChanged() {
    if (this.splashScreen) {
      this.$nextTick(() => {
        this.$nuxt.$loading.start()
      })
    } else {
      this.$nextTick(() => {
        this.$nuxt.$loading.finish()
      })
    }
  }

  public mounted() {
    this.$accessor.hideSplashScreen()
  }
}
