import { ApolloClient } from 'apollo-client'

class Apollo {
  public client!: ApolloClient<unknown>

  /**
   * Get the client object from the plugin
   */
  public init<T>(client: ApolloClient<T>) {
    this.client = client

    // using the config in apollo.config.ts does not work
    // see https://stackoverflow.com/questions/57415569/nuxt-js-apollo-client-how-to-disable-cache
    this.client.defaultOptions = {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
      mutate: {
        fetchPolicy: 'no-cache',
      },
    }
  }
}

const al = new Apollo()
export { al as ApolloService }
