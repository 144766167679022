import gql from 'graphql-tag'

export const CreateRegisteredAddressGQL = gql`
  mutation (
    $address: String!
    $postcode: String!
    $name: String!
    $phone: String!
  ) {
    createRegisteredAddress(
      address: $address
      postcode: $postcode
      name: $name
      phone: $phone
    )
  }
`
