import gql from 'graphql-tag'

export const GetMerchantsGQL = gql`
  query ($filter: MerchantFilter) {
    getMerchants(filter: $filter) {
      id
      uid
      email
      firstName
      lastName
      phone
      company
      savedContacts {
        id
        name
        phone
      }
      savedPlaces {
        id
        address1
        address2
        name
        lat
        lng
      }
      config {
        marketplace {
          enabled
          fee
        }
        pinCode {
          enabled
        }
        podSignature {
          enabled
        }
        bulk4PL {
          enabled
          fee
        }
        selfManaged {
          enabled
          fee
          groupCode
        }
        fixedPrice {
          bulkOrder {
            enabled
            fee
            pricePerStopSamePostalCode
          }
          standardOrder {
            enabled
            fee
            pricePerStopSamePostalCode
          }
        }
        qrCode {
          enabled
          isRequiredToScanWhenPickup
        }
        batchingTiming {
          enabled
          nextdayCutoffTime
          samedayCutoffTime
          sameDayPickupTimeWithinXHours
          sameDayPickupTimeWithinXHoursIfFoundAnyDeliveries
        }
      }
    }
  }
`
