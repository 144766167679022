import { GetProvidersGQL } from '../graphql/provider/GetProviders'
import { ApolloService } from './Apollo'
import { V1RidaApiGetProvidersResponse } from './Type'

class Provider {
  public static async getProviders() {
    const response =
      await ApolloService.client.query<V1RidaApiGetProvidersResponse>({
        query: GetProvidersGQL,
      })

    return response.data
  }
}

export { Provider as ProviderService }
