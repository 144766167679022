import { Dayjs } from 'dayjs'

import { GetOrderByObjectIdGQL } from '../graphql/order/GetOrderByObjectId'
import { GetOrdersGQL } from '../graphql/order/GetOrders'
import { RaceOrderGQL } from '../graphql/order/RaceOrder'
import { updateProviderDetailsGQL } from '../graphql/order/updateProviderDetails'
import { ApolloService } from './Apollo'
import { DEFAULT_FORMAT } from './Constant'
import { V1RidaApiGetOrderIdResponse, V1RidaApiGetOrdersResponse } from './Type'

class Order {
  public static async getOrders(filterOptions: {
    startDate: Dayjs
    endDate: Dayjs
    userId?: number[]
    itemsPerPage: number
    pageNumber: number
    statuses: string[]
    searchTerm: string
    providers: string[]
  }) {
    const response =
      await ApolloService.client.query<V1RidaApiGetOrdersResponse>({
        query: GetOrdersGQL,
        variables: {
          filter: {
            startDate: filterOptions.startDate.format(DEFAULT_FORMAT.DATE),
            endDate: filterOptions.endDate.format(DEFAULT_FORMAT.DATE),
            userId: filterOptions.userId,
            itemsPerPage: filterOptions.itemsPerPage,
            pageNumber: filterOptions.pageNumber,
            statuses: filterOptions.statuses,
            searchTerm: filterOptions.searchTerm,
            providers: filterOptions.providers,
          },
        },
      })

    return response.data
  }

  public static async getOrderByObjectId(orderId: string) {
    const response =
      await ApolloService.client.query<V1RidaApiGetOrderIdResponse>({
        query: GetOrderByObjectIdGQL,
        variables: { id: orderId },
      })

    return response.data
  }

  public static async updateProviderDetails(
    objectId: string,
    provider: string,
    deliveryId: string,
    cost: string,
    confirmation: boolean,
  ) {
    await ApolloService.client.mutate({
      mutation: updateProviderDetailsGQL,
      variables: {
        objectId,
        provider,
        deliveryId,
        cost,
        confirmation,
      },
    })
  }

  public static async raceOrder(objectId: string) {
    await ApolloService.client.mutate({
      mutation: RaceOrderGQL,
      variables: {
        objectId,
      },
    })
  }
}

export { Order as OrderService }
