import gql from 'graphql-tag'

export const GetOrderByObjectIdGQL = gql`
  query ($id: String!) {
    getOrderByObjectId(objectId: $id) {
      id
      objectId
      code
      pickupTime
      serviceType
      status
      delivery {
        type
        price
      }
      user {
        company
        email
        serviceLevel
      }
      deliveries {
        status
        objectId
        delivery {
          price
          provider
          deliveryId
        }
        waypoints {
          type
          address1
          address2
          location {
            lat
            lng
          }
          items {
            notes
            size
            category
            customId
          }
          name
          phone
          pod
        }
        driverInfo {
          name
          phone
          photo
          plateNumber
          location {
            lat
            lng
          }
        }
        completedAt
      }
      updatedAt
      createdAt
    }
  }
`
