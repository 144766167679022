import { Dayjs } from 'dayjs'

import { GetUnassignedPlannedRouteGQL } from '../graphql/report/GetUnassignedPlannedRoute'
import { TriggerBatchingGQL } from '../graphql/report/TriggerBatching'
import { ApolloService } from './Apollo'
import { DEFAULT_FORMAT } from './Constant'
import { V1RidaApiGetUnassignedPlannedRouteResponse } from './Type'

class Report {
  public static async getUnassignedPlannedRoute(date: Dayjs) {
    const response =
      await ApolloService.client.query<V1RidaApiGetUnassignedPlannedRouteResponse>(
        {
          query: GetUnassignedPlannedRouteGQL,
          variables: { date: date.format(DEFAULT_FORMAT.DATE) },
        },
      )

    return response.data
  }

  public static async triggerBatching(date: Dayjs) {
    const response = await ApolloService.client.mutate({
      mutation: TriggerBatchingGQL,
      variables: {
        date: date.format(DEFAULT_FORMAT.DATE),
      },
    })

    return response.data
  }
}

export { Report as ReportService }
