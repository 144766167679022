import axios from 'axios'

class Map {
  public static async getAddressFromPostalCode(postalCode: string) {
    const response = await axios.get<{
      results: {
        ADDRESS: string
        LATITUDE: string
        LONGITUDE: string
      }[]
    }>(
      `https://www.onemap.gov.sg/api/common/elastic/search?searchVal=${postalCode}&returnGeom=Y&getAddrDetails=Y`,
    )
    return response.data
  }
}

export { Map as MapService }
