import { GetRacingProvidersByDeliveryObjectIdGQL } from '../graphql/racing/GetRacingProvidersByDeliveryObjectId'
import { ApolloService } from './Apollo'
import { V1RidaApiGetRacingProvidersByDeliveryObjectIdReponse } from './Type'

class Racing {
  public static async getRacingProvidersByDeliveryObjectId(id: string) {
    const response =
      await ApolloService.client.query<V1RidaApiGetRacingProvidersByDeliveryObjectIdReponse>(
        {
          query: GetRacingProvidersByDeliveryObjectIdGQL,
          variables: {
            id,
          },
        },
      )
    return response.data
  }
}

export { Racing as RacingService }
