import gql from 'graphql-tag'

export const GetCreditTypesGQL = gql`
  {
    getCreditTypes {
      id
      name
      isNegative
      isPositive
      isDescriptionRequired
    }
  }
`
