import gql from 'graphql-tag'

export const UpdateCreditTransactionGQL = gql`
  mutation ($creditTransactionId: Int!, $createdTime: String) {
    updateCreditTransaction(
      creditTransactionId: $creditTransactionId
      createdTime: $createdTime
    )
  }
`
