import gql from 'graphql-tag'

export const GetCreditTransactionsByUserIdGQL = gql`
  query ($uid: String!, $filter: CreditFilter) {
    getCreditTransactionsByUserId(filter: $filter, uid: $uid) {
      totalItems
      transactions {
        id
        userId
        type
        referenceId
        createdTime
        amount
        description
        order {
          id
          objectId
          code
        }
      }
    }
  }
`
