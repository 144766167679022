import gql from 'graphql-tag'

export const UpdateRegisteredAddressGQL = gql`
  mutation (
    $objectId: String
    $address: String
    $postcode: String
    $name: String
    $phone: String
  ) {
    updateRegisteredAddress(
      objectId: $objectId
      address: $address
      postcode: $postcode
      name: $name
      phone: $phone
    )
  }
`
