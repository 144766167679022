import gql from 'graphql-tag'

export const ConfirmPodUploadGQL = gql`
  mutation (
    $waypointId: Int!
    $fileExtension: String!
    $dlvrdDocumentId: String
  ) {
    confirmPodUpload(
      waypointId: $waypointId
      fileExtension: $fileExtension
      dlvrdDocumentId: $dlvrdDocumentId
    )
  }
`
