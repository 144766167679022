import gql from 'graphql-tag'

export const GetPodPresignedUrlGQL = gql`
  mutation ($waypointId: Int!, $fileExtension: String!) {
    getPodPresignedUrl(waypointId: $waypointId, fileExtension: $fileExtension) {
      ridaPresignedUrls
      dlvrdPresignedUrl
      dlvrdDocumentId
    }
  }
`
