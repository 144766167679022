import { FirebaseService, UtilityService } from './services'

export default () => {
  const envConfig = UtilityService.getConfig()

  return {
    httpEndpoint: envConfig.RIDA.BACKEND_URL,
    getAuth: async () => {
      const token = await FirebaseService.getAccessToken()
      if (token) {
        return token
      }

      return ''
    },
    persisting: false,
    debug: !UtilityService.isProduction(),
    ssr: false,
    resolvers: {},
    defaultOptions: {},
  }
}
